<template>
  <v-dialog v-model="dialog" max-width="300px" style="z-index: 1;">
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Share Feed
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <!-- <v-card-text class="flex-row justify-center d-flex">
        <div v-html="eventUrlData"></div>
      </v-card-text> -->
      <v-card-text class="flex-row justify-center d-flex">
        <div>
          <div class="col-12">
            <!-- <ShareNetwork
              network="facebook"
              :url="computedUrl"
               title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="akinder,volunteer"
              @click.native="fb"
            >
              <v-btn text color="blue">
                <v-icon class="mr-3" color="blue">mdi-facebook</v-icon>Facebook
              </v-btn>
            </ShareNetwork> -->
            <v-btn text color="#0866FF" @click="shareOnFacebook">
              <v-icon class="mr-3" color="#0866FF">mdi-facebook</v-icon>Facebook
            </v-btn>
          </div>
          <!-- <div class="col-12">
            <v-btn text color="#E1306C" @click="shareOnInstagram" class="insta">
              <v-icon class="instagram-color" color="#E1306C"
                >mdi-instagram</v-icon
              >Instagram
            </v-btn>
          </div> -->

          <div class="col-12">
            <!-- <ShareNetwork
              network="twitter"
              :url="computedUrl"
              :title="computedTitle"
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="akinder,volunteer"
            >
              <v-btn text color="">
                <v-icon class="mr-3" color="">mdi-twitter</v-icon>Twitter
              </v-btn>
            </ShareNetwork> -->
            <v-btn text color="black" @click="shareOnTwitter">
              <img
                src="@/assets/twitter.png"
                alt="TwitterIcon"
                class="twitter-icon"
              />Twitter
            </v-btn>
          </div>

          <div class="col-12">
            <!-- <ShareNetwork
              network="linkedin"
              url="https://news.vuejs.org/issues/180"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="vuejs,vite"
            >
              <v-btn text color="blue">
                <v-icon class="mr-3" color="blue">mdi-linkedin</v-icon>LinkedIn
              </v-btn>
            </ShareNetwork> -->
            <v-btn text color="#0A66C2" @click="shareOnLinkedIn">
              <v-icon class="mr-3" color="#0A66C2">mdi-linkedin</v-icon>LinkedIn
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    eventUrlData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    onClose: {
      type: Function
    }
  },

  data() {
    return {
      url: "",
      maintitle: ""
    };
  },

  computed: {
    computedUrl() {
      return `${process.env.VUE_APP_WEB_URL}/backend/allHtml/eventHtml/get-event?_id=${this.eventUrlData._id}`;
    },
    computedTitle() {
      return this.eventUrlData.poster.name;
    },
    computedHashtags() {
      return "akinder,volunteer";
    },
    metaTags() {
      return {
        title: this.eventUrlData.title || "Default Event Title",
        meta: [
          {
            property: "og:title",
            content: this.eventUrlData.title || "Default Event Title"
          },
          {
            property: "og:description",
            content: this.eventUrlData.text || "Default Description"
          },
          {
            property: "og:image",
            content:
              this.eventUrlData.posterImage || "https://default-image.jpg"
          },
          {
            property: "og:url",
            content:
              this.url || "http://akindervolunteer.com/backend/default-url"
          },
          { property: "og:type", content: "website" }
        ]
      };
    }
  },

  methods: {
    ...mapActions("event", {}), // Import actions from vuex if needed

    // Open the URL on Facebook
    // fb() {
    //   console.log(JSON.stringify(this.eventUrlData), 'eventUrlData');
    //   // let uri = `${process.env.VUE_APP_WEB_URL}/backend/allHtml/eventHtml/get-event?_id=${this.eventUrlData._id}`;
    //   let uri = `${process.env.VUE_APP_WEB_URL}/event-details?_id=${this.eventUrlData._id}`;
    //   this.url = uri;
    //   try {
    //     let popup = window.open(this.url, '_blank', 'noopener,noreferrer');
    //     if (!popup) {
    //       // Fallback method if popup is blocked
    //       window.location.href = this.url;
    //     }
    //   } catch (e) {
    //     console.error('Popup blocked or other error:', e);
    //     // Fallback method if popup is blocked
    //     window.location.href = this.url;
    //   }
    //   console.log(this.url, 'uri');
    // },

    shareOnFacebook() {
      let eventUrl = `${process.env.VUE_APP_WEB_URL}/backend/allHtml/eventHtml/get-event?_id=${this.eventUrlData._id}`;
      let facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        eventUrl
      )}`;
      try {
        let popup = window.open(
          facebookShareUrl,
          "_blank",
          "noopener,noreferrer"
        );
        if (!popup) {
          // Fallback method if popup is blocked
          window.open(facebookShareUrl, "_blank");
        }
      } catch (e) {
        // Fallback method if popup is blocked
        window.open(facebookShareUrl, "_blank");
      }
    },
    shareOnTwitter() {
      const eventUrl = this.computedUrl;
      const text = this.computedTitle;
      const hashtags = this.computedHashtags;
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        eventUrl
      )}&text=${encodeURIComponent(text)}&hashtags=${encodeURIComponent(
        hashtags
      )}`;
      this.openShareWindow(twitterShareUrl);
    },
    shareOnLinkedIn() {
      const eventUrl = this.computedUrl;
      const title = this.computedTitle;
      const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        eventUrl
      )}&title=${encodeURIComponent(title)}`;
      this.openShareWindow(linkedInShareUrl);
    },
    shareOnInstagram() {
      alert(
        "To share on Instagram, please take a screenshot or download the image and upload it to Instagram."
      );
      const eventUrl = this.computedUrl;
      const instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
        eventUrl
      )}`;
      this.openShareWindow(instagramShareUrl);
    },
    openShareWindow(url) {
      try {
        const popup = window.open(url, "_blank", "noopener,noreferrer");
        if (!popup) {
          // Fallback method if popup is blocked
          window.open(url, "_blank");
        }
      } catch (e) {
        // Fallback method if popup is blocked
        window.open(url, "_blank");
      }
    }
  },

  // Using vue-meta to set meta tags dynamically
  metaInfo() {
    return this.metaTags;
  }
};
</script>

<style scoped>
.selected {
  background: #eef3f8;
}

.instagram-color {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-right: 11px;
}
.twitter-icon {
  margin-right: 11px;
  width: 20px;
  height: auto;
  margin-left: 4px;
}
</style>
